import React, { useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import { useGLTF, OrbitControls } from '@react-three/drei';
import { Suspense } from 'react';
import './App.css'; 
import { Model } from './Model';

export default function App() {
  return (
<div style={{ height: '100vh', 
              width: '100vw', 
              //backgroundImage: 'url("/background.png")', 
              //backgroundSize:'cover', 
              background: "transparent",
              //backgroundColor:"rgba(55,55,55,0.8)",
              overflow: 'hidden' }}>
 
 <Canvas shadows camera={{ position: [0, 0, 95], fov: 100 }}>
        <ambientLight intensity={5.5} />
        <directionalLight position={[0, 15, 5]} intensity={5.5} />
        <Suspense fallback={null}>
          <Model />
        </Suspense>
        <OrbitControls enableZoom={false} />
      </Canvas>
    </div>
  );
}


import React, { useEffect, useRef } from 'react';
import { useFrame } from '@react-three/fiber'; // Corrected import
import { useGLTF, useAnimations, Bounds } from '@react-three/drei';

export function Model(props) {
  const group = useRef(); // Create a ref for the group
  const { nodes, materials, animations } = useGLTF('/node1.glb')
  const { actions } = useAnimations(animations, group)

  useEffect(() => {
    
    // Play all animations (if you know specific names, you can play those specifically)
    Object.values(actions).forEach(action => action.play());

    // If you want to handle stopping, looping, or other properties, you can configure that here
    return () => {
      Object.values(actions).forEach(action => action.stop());
    };
  }, [actions]); // Re-run if actions change

  // Update loop for rotation
  useFrame(() => {
    if (group.current) {
        group.current.rotation.y += 0.001; // Adjust rotation speed here
    }
  });

    // Example offset values - you'll need to determine the appropriate values for your model
    const offsetX = 0;  // Adjust these based on your model's alignment needs
    const offsetY = 0.55;
    const offsetZ = 0;

  return (

    <group ref={group} {...props} dispose={null}>
       <group rotation={[Math.PI, -0.175, Math.PI]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0002.geometry}
          material={materials['Box Blue Metal.002']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh_0002_1.geometry}
          material={materials['05 - Default.002']}
        />
      </group>
    </group>
  );
}

useGLTF.preload('/node1.glb');
